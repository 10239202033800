
.ant-input     {
  height: auto;
}
.ant-btn   {
  height: 32px;
}
.ant-btn.strong   {
  font-weight: bold;
  border: 1px solid gray;
}
.ant-btn.success   {
  background: #27ae60;
  color: white;
  border: 1px solid #27ae60;
}
.ant-btn.success:hover   {
  background: #25964e;
}
.ant-alert-message {
  white-space: pre-wrap;
}
.ant-btn-link    {
  color: #363636;
  padding: 0;
  margin: 0;
  height: auto;
}
.ant-badge-count {
  min-width: 16px;
  height: 16px;
  padding: 0;
  font-size: 11px;
  line-height: 16px;
}

.ant-select-selection--multiple .ant-select-selection__choice {
    border: 1px solid gray;
}
.ant-list-item-meta-title    {
  margin-bottom: 0 !important;
  line-height: 1;
  margin-top: -5px !important;
}
.ant-list-item-meta-description    {
  font-size: 12px;
  margin-top: -3px;
}
.ant-modal-footer   {
  display: none;
}
.ant-drawer-body {
  margin-bottom: 80px;
}

/*
    PDF
 */
.pdf_drawer .ant-drawer-header    {
  background: #808080;
  border-bottom: 0 solid transparent;
}
.pdf_drawer .ant-drawer-header .ant-drawer-title, .pdf_drawer .ant-drawer-header i  {
  color: white;
}
.pdf_drawer .ant-drawer-content  {
  background: #808080
}
.pdf_drawer .ant-drawer-body {
  margin-bottom: 0;
  height: 92%;
  padding-top: 0;
}
.workplace    {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  background-color: white;
}
.workplaceBlock {
  margin-bottom: 20px;
  border: 1px solid #e09000;
  font-size: 15px;
}
.workplaceBlock button    {
    margin-top: 4px;
    background: #faa300;
    color: white;
    font-size: 12px;
    padding: 0 5px;
    height: 22px;
    margin-right: 2px;
    line-height: 20px;
    text-align: center;
  border: 0 solid transparent;
  cursor: pointer;
}
.workplaceBlock button:hover    {
  background: $mainColor;
}
.ant-spin-nested-loading   {
  min-height: 100px;
  display: block;
}
.ant-fullcalendar-header .ant-radio-group {
  display:none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  height: auto;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  height: 100px;
}
.hide-drop .ant-table-row-expand-icon-cell, .hide-drop .ant-table-row-expand-icon {
  visibility: hidden;
}
.ant-table-expanded-row-level-1  {
  background: $lightBcg !important;
}
div[contenteditable] {
  line-height: 1.6 !important;
}
.ant-table-title    {
  background: $lightBcg;
}

.checklist_item .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $green;
  border-color: $green;
}
.checklist_item .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white !important;
}
.ant-tag    {
  padding: 3px 12px;
  margin-bottom: 4px;
}
.ant-avatar>img {
  object-fit:cover;
}
/*
    GOOGLE CHARTS
 */

.google-visualization-orgchart-node {
  border: 2px solid transparent !important;
  vertical-align: top !important;
}
.google-visualization-orgchart-table * {
  padding: 0 !important;
}

/*
    GANTT
 */
.details-container {
  background: #fff;
  display: inline-block;
  padding: 12px;
  min-width: 300px;
  font-size: 13px;
  border: 1px solid $borderColor;
  box-shadow: 1px 1px 3px #cdcdcd;
}
.bar-group.red  rect  {
  fill: $red !important;
}
.handle-group   {
  display: none;
}
.gantt .bar-wrapper .bar-progress {
  fill: $green !important;
}
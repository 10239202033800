@import "./colors";
@import "./auth";
@import "./override";

.mb_15 {
    margin-bottom: 15px !important
}
.mb_30 {
    margin-bottom: 30px !important
}
.mb_5 {
    margin-bottom: 5px !important
}
.mt_15 {
    margin-top: 15px
}

.m_15 {
    margin: 15px;
}

.p_15 {
    padding: 15px;
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.pull-right {
    float: right
}

.marginless {
    margin: 0;
}

.hide {
    display: none
}

.show {
    display: block
}
.pre_wrap {
    white-space: pre-wrap;
}
.border {
    border: 1px solid $borderColor
}


body {
    background: $lightBcg;
}

.container-sm {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}
.table      {
    width: 100%;
}
.table th       {
    background: $lightBcg;
}
.table, .table th, .table td {
    border: 1px solid $borderColor;
}
.table th, .table td {
    padding: 10px 15px;
}
.field_label {
    margin-bottom: 15px;
}
.pdf_title   {
    background: $mainColor;
    color: white;
    font-weight: 500;
    text-align: center;
}
.pdf_title_padding   {
    padding: 7px 12px;
}

.field_label > span:first-child {
    display: block;
    margin-bottom: 5px;
}

.field_label p {
    color: $darkColor;
    font-size: 15px;
}

a.black {
    color: $darkColor
}

a.black:hover {
    text-decoration: underline;
}

.app_loader, .spin_loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spin_loader {
    height: 240px;
}

.avatar {
    background: $red;
    margin-top: -3px;
    margin-right: 5px;
}
.avatar.transparent {
    background: transparent !important;
}
/*
    EDITOR
 */
.editor_editable {
    border: 1px solid $borderColor;
    border-radius: 12px;
    padding: 5px 10px;
    overflow: hidden;
    background: white;
}

.editor_editable [contenteditable] {
    outline: none;
}

.editor_icons button {
    margin-right: 10px;
    border: 1px solid transparent;
}

.editor_icons i:hover {
    color: $darkColor;
}


/*
    DASHBOADD
 */
.dashboard_header {
    position: fixed;
    background: white;
    width: 100%;
    border-bottom: 1px solid $borderColor;
    z-index: 15;
    top: 0;
    padding: 10px 0;
}

.dashboard_menu_user {
    text-align: right;
    font-weight: bold;
}

.dashboard_menu {
    position: fixed;
    height: 100vh;
    width: 260px;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-x: hidden;
    overflow-y: auto;
    left: 0;
    top: 0;
    z-index: 12;
}

.dashboard_menu_overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.3;
    top: 0;
    left: 0;
    z-index: 11;
}

.dashboard_content {
    margin-top: 80px;
    margin-bottom: 100px;
    margin-left: 260px;
}

.dashboard_title {
    font-size: 22px;
    margin-bottom: 15px;
}

.dashboard_block {
    border: 1px solid $borderColor;
    background: white;
}

.responsive_show {
    display: none;
}

@media only screen and (max-width: 780px) {
    .dashboard_menu {
        left: -260px;
        -webkit-transition: left .4s; /* Safari prior 6.1 */
        transition: left .4s;
    }
    .dashboard_menu_open {
        left: 0;
    }
    .dashboard_content {
        margin-left: 0;
    }
    .responsive_hide {
        display: none !important;
    }
    .responsive_show {
        display: block !important;
    }
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .menu_toggle_btn {
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 50px;
        height: 50px;
        z-index: 13;
    }
    .hide_2 table td:nth-child(2), .hide_2 table th:nth-child(2) {
        display: none;
    }
    .hide_3 table td:nth-child(3), .hide_3 table th:nth-child(3) {
        display: none;
    }
    .hide_4 table td:nth-child(4), .hide_4 table th:nth-child(4) {
        display: none;
    }
    .hide_5 table td:nth-child(5), .hide_5 table th:nth-child(5) {
        display: none;
    }
}

/*
    DRAWER
 */
.drawer_footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid $borderColor;
    padding: 10px 16px;
    background: #fff
}


/*
  COMMENTS
 */
.comment_block {
    padding: 0 15px;
    margin-bottom: 15px;
}

.subcomment_block {
    margin-left: 50px;
    background: $lightBcg;
    padding: 10px 15px;
    border-radius: 8px;
}

.comment_editor {
    padding: 15px 0;
    margin-left: 50px;
}

.comment_block .ant-list-item {
    border-bottom: 1px solid transparent !important;
}

/*
    projectS
 */
.group_description {
    height: 25px;
    overflow: hidden;
}

/*
  TASKS
 */
.tasks_gantt_container {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.tasks_header {
    padding: 15px;
    margin-bottom: 30px !important;
}

.tasks_header a {
    color: $darkColor;
    font-size: 16px;
    margin-right: 10px;
}

.tasks_header a.active {
    color: $mainColor;
}

.passed {
    color: #bebebe !important;
    text-decoration: line-through;
}

/*
    ORG CHART
 */
.org_block {

}

.org_header {
    border-bottom: 1px solid $borderColor;
    font-weight: bold;
    font-size: 14px;
    padding: 6px 12px !important;
}

.org_content {
    min-height: 80px;
}

.org_employee {
    border-bottom: 1px solid $borderColor;
    padding: 4px 15px !important;
    font-size: 14px;
}

.org_employee:hover, .org_header:hover {
    cursor: pointer;
    background: white;
}

.org_employee:last-child {
    border-bottom: 0 solid transparent;
}

/*
  FOLDER
 */
.folder {
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    width: 80px;
    display: inline-block;
    margin: 10px;
    border: 1px solid transparent;
    vertical-align: top;
}

.folder:hover {
    background: $lightBcg;
}

.folder.active {
    border: 1px solid $mainColor;
}

.folder_img_container {
    margin: 3px 6px;
    position: relative;
}

.folder img {
    max-width: 100%;
}

.folder_lock_icon {
    position: absolute;
    bottom: 12px;
    right: 7px;
    color: white;

}

.folder_title {
    max-height: 40px;
    overflow: hidden;
    margin-bottom: 4px;
    font-size: 13px;
    word-break: break-all;
}

.folder:hover .folder_title, .folder.active .folder_title {
    overflow: visible;
}

.preloader {
    position: relative;
}

.preloader img {
    position: absolute;
    top: 0;
    left: 0;
}

/*
    CHECKLISTS
 */
.checklist_item {
    margin-bottom: 10px;
    border-bottom: 1px dashed $borderColor;
    padding-bottom: 10px;
}

.checklist_form_item {
    border: 1px dashed $borderColor;
    background: white;
    padding: 10px;
    margin-bottom: 5px;
}

/*
  POPUP
 */
.popup_modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.popup_close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.popup_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.3;
}

.popup_content {
    position: absolute;
    top: 50%; /* 3 */
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 90%;
    border: 2px solid red
}

.popup_modal img {
    position: absolute;
    top: 50%; /* 3 */
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 90%;
}

/*
    OFFER PRODUCT
 */
.offer_product_block     {
    border: 1px solid $borderColor;
    padding: 15px;
    margin-bottom: 15px;
}
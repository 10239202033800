

$mainColor: #ED1C24;
$darkColor: #363636;
$lightBcg: #f1f5f9;
.light_bcg { background: $lightBcg !important; }
$borderColor: lightgray;
.main { color: $mainColor }

$green: #009f00;
.green { color: $green; }
$red: #ED1C24;
.red { color: $red; }
a.red:hover { color: $red; }

$info: #3498db;
$alert: #e67e22;
$warning: #e74c3c;
.info {color: $info}
.alert {color: $alert}
.warning {color: $warning}


.auth_layout    {
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
}
.auth_logo   {
  max-width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.facebook_btn   {
  color: white;
  background: #3b5998;
}
.facebook_btn:hover, .facebook_btn:focus   {
  color: #3b5998;
  border: 1px solid #3b5998;
}
.google_btn   {
  border: 1px solid gray;
}
.google_btn:hover, .google_btn:focus   {
  border: 1px solid #4d4d4d;
  color: #333333;
  font-weight: bold;
}
